import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MaintenancePageComponent } from "./maintenance-page/maintenance-page.component";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";

const routes: Routes = [
  {
    path: "maintenance",
    component: MaintenancePageComponent,
  },
  {
    path: "**",
    component: MaintenancePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
